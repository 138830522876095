<template>
  <div class="panel">
    <div class="inset-content">
      <form method="post" @submit.prevent="submitLogin" id="login-form">
        <h1 class="h3 mb-3 fw-normal">Login</h1>

        <label for="inputEmail" class="control-label">Email address</label>
        <input
          type="text"
          id="inputEmail"
          placeholder="username"
          v-model="form.username"
          required
          autofocus="" />
        <label for="inputPassword" class="control-label">Password</label>
        <input
          type="password"
          id="inputPassword"
          v-model="form.password"
          placeholder="password"
          required />

        <div>
          <label>
            <input class="control-label" type="checkbox" value="remember-me" />
            Remember me
          </label>
        </div>
        <button class="button" type="submit">Sign in</button>
      </form>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'
import axios from 'axios'

export default {
  name: 'LoginItem',
  setup() {
    // Setup Toast notifcations
    const toast = useToast()
    return { toast }
  },
  methods: {
    submitLogin: async function () {
      let authScript = process.env.VUE_APP_ROOT_API + 'admin/retrieve_token.php'          
      axios
        .post(
          `${authScript}`,
          this.form, // the data to post
          {
            headers: {
              'Content-type': 'application/x-www-form-urlencoded',
            },
          }
        )
        .then(async (response) => {
          // Username and password was correct and we've received the JSON web token.
          this.$store.commit('setToken', response.data)
          this.$router.push({ path: '/admin' })
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 401) {
              this.toast.error(
                (this.error = 'Invalid username and/or password')
              )
            } else if (error.request) {
              // client never received a response, or request never left
              this.toast.error((this.error = `An error occured: ${error}`))
            } else {
              this.toast.error(
                (this.error = `An unknown error occured: ${error}`)
              )
            }
          } else {
            this.toast.error((this.error = `${error}`))
          }
        })
    },
  },
  data() {
    return {
      form: {
        username: '',
        password: '',
      },
    }
  },
}
</script>

<style>
#login-form {
  display: grid;
  gap: 1em;
  font-size: 1.5vh;
}

#inputEmail,
#inputPassword {
  width: 30vmin;
}
</style>